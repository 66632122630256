import MobileDetect from 'mobile-detect';

/**
 * Detect if a user agent belongs to an Android or iOS device.
 * The detection is not 100% reliable but it provides a good guess.
 */
export const isAndroidOrIosFromUserAgent = (userAgent: string): boolean => {
    return ['AndroidOS', 'iOS', 'iPadOS'].includes(new MobileDetect(userAgent).os());
};

// Define bot user agents that we want to skip for error reporting
const botUserAgents = ['Google-Read-Aloud'];

/**
 * Detect if a user agent is a user-initiated bot like Google Read Aloud.
 * These bots should be allowed but we might not want to report errors caused by them.
 */
export const isUserInitiatedBot = (userAgent: string | undefined): boolean => {
    if (!userAgent) return false;
    // Check if the user agent contains any known bot signatures
    return botUserAgents.some((bot) => userAgent.includes(bot));
};
