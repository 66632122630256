import { PUBLIC_SENTRY_ENV } from '$env/static/public';
import { version } from '$lib/../../../../version.json';
import { dsn } from '$lib/constants/sentryConstants';
import { isUserInitiatedBot } from '$lib/helpers/userAgentHelper';
import { handleErrorWithSentry, init } from '@sentry/sveltekit';

init({
    dsn,
    environment: PUBLIC_SENTRY_ENV,
    sampleRate: 1.0,
    release: 'v' + version,

    ignoreErrors: [
        // Email link Microsoft Outlook crawler compatibility error
        // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
        'Non-Error promise rejection captured with value: Object Not Found Matching Id:'
    ],
    beforeSend(event) {
        const userAgent = event?.request?.headers?.['User-Agent'];
        // Returning null prevents the event from being sent to Sentry
        if (isUserInitiatedBot(userAgent)) {
            return null;
        }

        return event;
    }
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
